import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image';

import { BREAKPOINTS } from '../styles/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  featuredImage: {
    maxHeight: 1,
    [BREAKPOINTS.md]: {
      maxHeight: 200,
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
}));

const Image = ({ src, ...props }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => src === node.relativePath),
    [data, src],
  );

  return (
    <div className={classes.root}>
      <Img
        className={classes.featuredImage}
        fluid={match.node.childImageSharp.fluid}
        {...props}
      />
      <div style={{ flex: 1 }} />
      <div className={classes.overlay} />
    </div>
  );
};

export default Image;
