import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import formatDate from '../formatDate';
import { spacing, COLORS } from '../styles/constants';
import DanImage from './DanImage';
import Comments from './Comments';

const useStyles = makeStyles(theme => ({
  root: {},
  signatureTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: spacing(3),
  },
  signature: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
  },
  avatar: {
    marginRight: spacing(1),
  },
  subtext: {
    color: COLORS.thisGray,
    marginBottom: spacing(1),
  },
  name: {
    fontSize: 20,
    marginBottom: spacing(1),
  },
  title: {
    color: 'black',
    marginTop: spacing(5),
  },
  html: {},
}));

export default function BlogPost({ isPreview, page }) {
  const classes = useStyles();
  let html = page.html;

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            about
          }
        }
      }
    `,
  );
  return (
    <div className={classes.root}>
      <header className={classes.title}>
        <Typography variant="h1">{page.frontmatter.title}</Typography>
        <span>{page.frontmatter.baseline}</span>
      </header>
      <div style={{ marginBottom: '4rem' }} className={classes.signatureTop}>
        <Avatar className={classes.avatar}>D</Avatar>

        <Typography variant="subtitle1">
          Dan Willoughby・ {formatDate(page.frontmatter.date)}・
          {page.timeToRead} min read
        </Typography>
      </div>
      <div
        className={classes.html}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <Divider />
      <div className={classes.signature}>
        <DanImage />
        <div>
          <Typography className={classes.subtext}>WRITTEN BY</Typography>
          <Typography className={classes.name}>Dan Willoughby</Typography>

          <Typography
            variant="subtitle1"
            className={classes.subtext}
            dangerouslySetInnerHTML={{ __html: site.siteMetadata.about }}
          />
        </div>
      </div>
      <Divider />
      <Comments uuid={page.frontmatter.uuid} />
    </div>
  );
}

export const query = graphql`
  fragment BlogPost on MarkdownRemark {
    id
    html
    frontmatter {
      title
      path
      date
      image
      preview_cutoff
      uuid
    }
    timeToRead
  }
`;
