import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';

import { formatRailsDate } from '../formatDate';

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: '2rem',
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function Comments({ uuid }) {
  const classes = useStyles();
  let [username, setUsername] = useState('Anonymous');
  let [title, setTitle] = useState('');
  let [comment, setComment] = useState('');
  let [comments, setComments] = useState([]);
  let [commentsLoading, setCommentsLoading] = useState(true);
  let [commentsFailed, setCommentsFailed] = useState(false);
  const host = 'https://web.pushback.io/plainice_comments';
  let onSubmit = async e => {
    e.preventDefault();
    let newComment = {
      uuid,
      username,
      title,
      text: comment,
    };
    try {
      const response = await fetch(host, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify(newComment),
      });
      let comment = await response.json();
      setComments([comment, ...comments]);
      setTitle('');
      setComment('');
    } catch (e) {}
  };

  useEffect(() => {
    let getComments = async () => {
      try {
        const response = await fetch(`${host}?uuid=${uuid}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        setCommentsLoading(false);
        let comments = await response.json();

        setComments(comments);
      } catch (e) {
        setCommentsFailed(true);
      }
    };
    getComments();
  }, [host, uuid]);

  if (commentsFailed) {
    return null;
  }
  if (commentsLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <h2 className={classes.header}>Comments</h2>
      <form className={classes.form} onSubmit={onSubmit}>
        <Grid className={classes.container} spacing={1} container>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={e => setUsername(e.target.value)}
              id="username"
              label="Username"
              name="username"
              value={username}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={e => setTitle(e.target.value)}
              id="title"
              label="Title"
              name="title"
              value={title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              multiline
              value={comment}
              onChange={e => setComment(e.target.value)}
              name="comment"
              label="Comment"
              type="comment"
              id="comment"
            />
          </Grid>
        </Grid>
        <Button type="submit" fullWidth variant="contained" color="primary">
          Comment
        </Button>
      </form>
      <List className={classes.root}>
        {comments.map(comment => (
          <ListItem key={comment.id} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={comment.username}>
                {comment.username.substring(0, 1)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${formatRailsDate(comment.created_at)} — ${
                comment.username
              } — ${comment.title}`}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {comment.text}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}
