import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import BlogLayout from '../components/BlogLayout';
import { BREAKPOINTS } from '../styles/constants';
import BlogPostImage from '../components/BlogPostImage';
import BlogPost from '../components/BlogPost';
import SEO from '../components/seo';

const useStyles = makeStyles(theme => ({
  main: {
    paddingTop: 16,
    [BREAKPOINTS.sm]: {
      paddingTop: 24,
    },
    [BREAKPOINTS.md]: {
      paddingTop: 32,
    },
  },
  postImage: {
    marginBottom: 16,
    maxHeight: 300,
  },
}));

export default function Template(props) {
  const classes = useStyles();
  const { markdownRemark: page } = props.data;
  const { placeholderImage: image } = props.data;
  const { site } = props.data;
  let meta = [];

  if (page.frontmatter.image) {
    meta = [
      {
        property: 'og:image',
        content: image.childImageSharp.fixed.src,
      },
      {
        property: 'twitter:image',
        content: `${site.siteMetadata.siteUrl}${image.childImageSharp.fixed.src}`,
      },
    ];
  }

  return (
    <BlogLayout isBlog>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.lede}
        meta={meta}
        keywords={page.frontmatter.keywords}
      />
      <Container maxWidth="md" className={classes.main}>
        {page.frontmatter.image && (
          <BlogPostImage
            className={classes.postImage}
            src={page.frontmatter.image}
          />
        )}
        <BlogPost page={page} />
      </Container>
    </BlogLayout>
  );
}

export const pageQuery = graphql`
  query DocsByPath($path: String!, $image: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
        lede
        keywords
      }
      ...BlogPost
    }
    placeholderImage: file(relativePath: { eq: $image }) {
      childImageSharp {
        fixed {
          src
        }
      }
    }
  }
`;
